import { ChakraProvider } from '@chakra-ui/react'

import GlobalStyles from './styles/global'
import { theme, CSSReset, GlobalStyle } from './styles/theme'

type Props = {
  children?: React.ReactNode
}
const AppProviders: React.FC<Props> = ({ children }) => {
  return (
    <>
      <ChakraProvider theme={theme}>
        <CSSReset />
        <GlobalStyles />
        <GlobalStyle />
        <>{children}</>
      </ChakraProvider>
    </>
  )
}

export default AppProviders
