import { Global } from '@emotion/react'

const GlobalStyles = () => (
  <Global
    styles={{
      '*': {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat',
        fontWeight: '600',
        boxSizing: 'border-box',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale'
      },
      '::-webkit-scrollbar': {
        height: '7px',
        width: '7px',
        backgroundColor: '#EBEBEB',
        borderRadius: '7px'
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '7px',
        backgroundColor: '#898989'
      },
      html: {
        fontSize: '16px',
        scrollBehavior: 'smooth'
      }
    }}
  />
)

export default GlobalStyles
