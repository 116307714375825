import { initializeApp } from 'firebase/app'

import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import { getUsersByEmail, updateUser } from '../../../services/UserService'

// import { getUsersByEmail, updateUser } from '../services/UserService'

const firebaseConfig = {
  apiKey: "AIzaSyCtONTFIjayMl8luNRN6xvWN-96DqW0Lbc",
  authDomain: "optimal-card-399123.firebaseapp.com",
  projectId: "optimal-card-399123",
  storageBucket: "optimal-card-399123.appspot.com",
  messagingSenderId: "670321990401",
  appId: "1:670321990401:web:663e329877286332131900",
  measurementId: "G-GEYFB0EZCH",
}

const oAuth = initializeApp(firebaseConfig)
export const getOAuth = getAuth(oAuth)

const signIn = async (): Promise<any> => {
  try {
    const provider = new GoogleAuthProvider()

    const result: any = await signInWithPopup(getOAuth, provider)

    const email = result.user.email
    const profilePic = result.user.photoURL
    const accessToken = result.user.accessToken

    const user = await getUsersByEmail(email)

    if (
      (user?.data?.photo_path === '' ||
        user?.data?.photo_path === undefined ||
        user?.data?.photo_path === '/') &&
      profilePic
    ) {
      if (user.data) {
        const newUser = { id: user.data.id, photo_path: user.data.photo_path }

        newUser.photo_path = profilePic

        await updateUser({
          id: newUser.id,
          photo_path: newUser.photo_path
        })
      }
    }

    localStorage.setItem('id', user?.data?.id || '')
    localStorage.setItem('name', user?.data?.name || '')
    localStorage.setItem('email', user?.data?.email || '')
    localStorage.setItem(
      'admission_date',
      String(user?.data?.admission_date) || ''
    )
    localStorage.setItem('user_type', user?.data?.user_type || '')
    localStorage.setItem(
      'creation_date',
      String(user?.data?.creation_date) || ''
    )
    localStorage.setItem('is_active', user?.data?.is_active || '')
    localStorage.setItem('manager', user?.data?.manager || '')
    localStorage.setItem('subscriber', user?.data?.subscriber || '')
    localStorage.setItem('usertype', user?.data?.usertype || '')
    localStorage.setItem('profilePic', profilePic || '')
    localStorage.setItem('accessToken', accessToken || '')
    localStorage.setItem('isLogged', 'true' || '')

    const responseData = {
      accessToken,
      email,
      name: user?.data?.name,
      profilePic,
      isLogged: true,
      id: user?.data?.id,
      admission_date: String(user?.data?.admission_date),
      user_type: user?.data?.user_type,
      creation_date: String(user?.data?.creation_date),
      is_active: user?.data?.is_active,
      manager: user?.data?.manager,
      subscriber: user?.data?.subscriber,
      usertype: user?.data?.usertype
    }

    return responseData
  } catch (err) {
    throw err
  }
}

export default signIn
