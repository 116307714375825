import { ECollections, firebaseServices } from './firebase'
import { IUser } from '../interfaces/IUser'
import { IFirebaseResponse } from '../interfaces/IFirebaseResponse'

export async function getUsersByEmail(
  email: string
): Promise<IFirebaseResponse<IUser>> {
  const result: IFirebaseResponse<IUser> = {
    data: null,
    error: null
  }

  try {
    const firebase = new firebaseServices()
    const userDocuments: IUser[] = (
      await firebase.findDocumentByQuery(
        ECollections.USER,
        'email',
        '==',
        email
      )
    ).map(
      (user) =>
        ({
          id: user.id,
          name: user.data.first_name,
          email: user.data.email,
          admission_date: user.data.admission_date,
          user_type: user.data.user_type,
          creation_date: user.data.creation_date,
          is_active: user.data.is_active,
          manager: user.data.manager,
          subscriber: user.data.subscriber,
          usertype: user.data.user_type,
          photo_path: user.data.photo_path
        }) as IUser
    )
    result.data = userDocuments[0]
  } catch (e) {
    result.error = e
  }

  return result
}

export async function getUsersById(
  id: string
): Promise<IFirebaseResponse<IUser[]>> {
  const result: IFirebaseResponse<IUser[]> = {
    data: null,
    error: null
  }

  try {
    const firebase = new firebaseServices()
    const userDocuments: IUser[] = (
      await firebase.findDocumentByQuery(ECollections.USER, 'id', '==', id)
    ).map(
      (user) =>
        ({
          id: user.id,
          name: user.data.first_name,
          email: user.data.email,
          admission_date: user.data.admission_date,
          user_type: user.data.user_type,
          creation_date: user.data.creation_date,
          is_active: user.data.is_active,
          manager: user.data.manager,
          subscriber: user.data.subscriber,
          usertype: user.data.user_type,
          photo_path: user.data.photo_path
        }) as IUser
    )

    result.data = userDocuments
  } catch (e) {
    result.error = e
  }

  return result
}

export async function updateUser(user: any): Promise<IFirebaseResponse<IUser>> {
  const result: IFirebaseResponse<IUser> = {
    data: null,
    error: null
  }

  try {
    const firebase = new firebaseServices()
    const userDocuments: IUser = await firebase.updateDocument(
      ECollections.USER,
      user,
      user.id
    )

    result.data = userDocuments
  } catch (e) {
    result.error = e
  }

  return result
}
