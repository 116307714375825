import { Flex } from '@chakra-ui/react'

type Props = {
  children?: React.ReactNode
}
const LoggedOut: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Flex
        bg="grayBg"
        h="100vh"
        w="100vw"
        color="black"
        flexDir="column"
        fontSize={'xl'}
      >
        {children}
      </Flex>
    </>
  )
}

export default LoggedOut
