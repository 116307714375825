import React, { lazy } from "react";

import LayoutLogged from "../layouts/Logged";
import IPropLayoutLogged from "../layouts/Logged/types";

import IPropLayoutLoggedOut from "../layouts/LoggedOut/types";
import LoggedOut from "../layouts/LoggedOut";

const Login = lazy(() => import("../pages/Login"));

const QrCodeReaderPage = lazy(() => import("../pages/QrCodeView"));
const PlanOptionsPage = lazy(() => import("../pages/PlanOptions"));

interface IProp {
  path: string;
  title: string;
  name?: string;
  component: React.FC;
  layout: React.FC<IPropLayoutLogged> | React.FC<IPropLayoutLoggedOut>;
  isPublic?: boolean;
  params?: string[];
  breadcrumbs?: boolean;
}

export const routes: IProp[] = [
  {
    path: "/",
    title: "Dugole Club - Login",
    component: Login,
    isPublic: true,
    layout: LoggedOut,
  },
  {
    path: "/sale",
    title: "Dugole Club - Confirmação",
    component: QrCodeReaderPage,
    isPublic: false,
    layout: LayoutLogged,
  },
  {
    path: "/options",
    title: "Dugole Club - Plan",
    component: PlanOptionsPage,
    isPublic: false,
    layout: LayoutLogged,
  },
];
