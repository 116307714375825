import { useHistory } from 'react-router-dom'

import logo from '../../assets/logo/Logotipo - Dugole.svg'
import { motion } from 'framer-motion'

import LoggedOut from '../../layouts/LoggedOut'
import { Flex, Text, Img } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const NotFoundPage: React.FC = () => {
  const history = useHistory()
  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1)
    }, 1000)

    setTimeout(() => {
      clearInterval(timer)

      // Redirecionar para a página desejada após 5 segundos
      history.goBack()
    }, 5000)

    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LoggedOut>
      <Flex
        w="100%"
        h="100%"
        flex="1"
        align={'center'}
        justify={'center'}
        flexDir="column"
        gap="3"
      >
        <motion.div
          animate={{ scale: [1, 1.2, 1] }} // Animação de escala que cria o efeito de pulsação
          transition={{ duration: 1, repeat: Infinity }}
        >
          <Img src={logo} alt="logo" />
        </motion.div>
        <Text color="black">404 - Página não encontrada</Text>
        <Text color="black">Voltando em {countdown}</Text>
      </Flex>
    </LoggedOut>
  )
}

export default NotFoundPage
