import { Switch, Route } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import { routes } from './routes'
import { Suspense } from 'react'
import LoadingPage from '../components/LoadingPage'
import NotFoundPage from '../pages/NotFoundPage'

const Router: React.FC = () => {
  return (
    <Switch>
      {routes.map((item) => {
        const Component = item.component
        const Layout = item.layout
        return (
          <Route key={item.path} path={item.path} exact>
            <Helmet>
              <title>{item.title}</title>
            </Helmet>
            <Layout isPublic={item.isPublic} {...item}>
              <Suspense fallback={<LoadingPage />}>
                <Component />
              </Suspense>
            </Layout>
          </Route>
        )
      })}
      <Route>
        <Helmet>
          <title>404 - Página não encontrada</title>
        </Helmet>

        <NotFoundPage />
      </Route>
    </Switch>
  )
}

export default Router
