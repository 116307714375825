import { createStore } from 'effector'

import { IMenu } from './types'

import * as actions from './actions'

const initialState = {
  isVisible: false,
  isActive: null,
  title: localStorage.getItem('title') || ''
}

export const store = createStore<IMenu>(
  {
    ...initialState
  },
  {
    name: 'menu'
  }
)

store.on(actions.handleSetActive, (state, payload) => {
  const info = {
    ...state,
    isActive: payload
  }

  return {
    ...info
  }
})

store.on(actions.handleSetIsVisible, (state, payload) => {
  const info = {
    ...state,
    isVisible: payload
  }

  return {
    ...info
  }
})

store.on(actions.handleSetTitle, (state, payload) => {
  const info = {
    ...state,
    title: payload
  }
  localStorage.setItem('title', payload)

  return {
    ...info
  }
})
