import { Flex, Img } from '@chakra-ui/react'
import logo from '../../assets/logo/Logotipo - Dugole.svg'
import { motion } from 'framer-motion'

const LoadingPage: React.FC = () => {
  return (
    <Flex
      pos="fixed"
      top="0"
      left={0}
      align={'center'}
      justify={'center'}
      flexDir={'column'}
      bg="white"
      h={'100%'}
      w="100%"
      zIndex={999}
    >
      <motion.div
        animate={{ scale: [1, 1.2, 1] }} // Animação de escala que cria o efeito de pulsação
        transition={{ duration: 1, repeat: Infinity }}
      >
        <Img src={logo} alt="logo" />
      </motion.div>
    </Flex>
  )
}

export default LoadingPage
