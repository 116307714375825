import {
  Flex,
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Avatar,
  Text,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useMenu } from "../../modules/menu/hooks";
import { useRef } from "react";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { useAuth } from "../../modules/auth/hooks";

const Menu: React.FC = () => {
  const { handleSetIsVisible, isVisible, handleSetTitle } = useMenu();
  const btnRef = useRef<any>();
  const link = useHistory();
  const { data, handleLogout } = useAuth();
  const nameSize = useBreakpointValue({ base: true, md: false, lg: false });

  const menus = [
    {
      id: "0",
      name: "Sair do Web App",
      link: "/",
      onClick: handleLogout,
      subItems: [
        {
          name: "",
          titlePage: "",
          link: "/",
        },
      ],
    },
  ];

  return (
    <Flex>
      <Drawer
        isOpen={isVisible}
        placement="left"
        onClose={() => handleSetIsVisible(!isVisible)}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg="white" color="black">
          <DrawerHeader bg="yellow" color="white">
            <Flex align={"center"}>
              <Box>
                <Avatar
                  name={data.name || ""}
                  src={data.profilePic || ""}
                  size="md"
                  mr="3"
                />
              </Box>

              <Flex flexDir={"column"} mr={5}>
                <Tooltip
                  label={data.name}
                  aria-label="A tooltip"
                  bg="black"
                  placement="top"
                >
                  <Text isTruncated maxW="200px" fontSize={"md"}>
                    {nameSize ? (
                      <>
                        <>{data.name}</>
                      </>
                    ) : (
                      <>{data.name}</>
                    )}
                  </Text>
                </Tooltip>

                <Tooltip
                  label={data.email}
                  aria-label="A tooltip"
                  bg="black"
                  placement="top"
                >
                  <Text fontSize={"sm"} isTruncated maxW="200px">
                    {data.email}
                  </Text>
                </Tooltip>
              </Flex>
              <DrawerCloseButton size={"xl"} color="white" mt="4" />
            </Flex>
          </DrawerHeader>

          <DrawerBody p="0" m="0">
            <Accordion allowToggle defaultIndex={[0]}>
              {menus.map((item, index) => {
                return (
                  <>
                    <AccordionItem
                      key={`${item.name}-${index}`}
                      fontSize={"sm"}
                      onClick={() => item.onClick && item.onClick()}
                    >
                      <h2>
                        <AccordionButton
                          borderBottom={"1px solid"}
                          borderColor={"grayLight"}
                        >
                          <Box as="span" flex="1" textAlign="left">
                            {item.name}
                          </Box>

                          {item?.subItems && item?.subItems?.length > 0 && (
                            <AccordionIcon />
                          )}
                        </AccordionButton>
                      </h2>
                      {item?.subItems && item?.subItems?.length > 0 && (
                        <>
                          {item?.subItems?.map((item, key) => (
                            <AccordionPanel
                              p="0"
                              m="0"
                              key={`${item.link}-${key}`}
                              bg={
                                item.link === link.location.pathname
                                  ? "grayLight"
                                  : "grayBg"
                              }
                            >
                              <ChakraLink
                                as={ReactRouterLink}
                                to={item.link}
                                onClick={() => {
                                  handleSetTitle(item.titlePage);
                                  handleSetIsVisible(!isVisible);
                                }}
                              >
                                <Flex
                                  cursor="pointer"
                                  p="3"
                                  borderBottom={"1px solid"}
                                  borderColor={"grayLight"}
                                >
                                  <Text>{item.name}</Text>
                                </Flex>
                              </ChakraLink>
                            </AccordionPanel>
                          ))}
                        </>
                      )}
                    </AccordionItem>
                  </>
                );
              })}
            </Accordion>
          </DrawerBody>

          <DrawerFooter>
            {/* <Button colorScheme="blue">Save</Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Menu;
