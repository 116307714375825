import { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import AppProviders from "./AppProviders";
import { BrowserRouter, useLocation } from "react-router-dom";
import Routes from "./routes";

function ScrollToTopOnMount() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  return (
    <ChakraProvider>
      <AppProviders>
        <BrowserRouter>
          <ScrollToTopOnMount />
          <Routes />
        </BrowserRouter>
      </AppProviders>
    </ChakraProvider>
  );
}

export default App;
