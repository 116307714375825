// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  WhereFilterOp,
  getDoc,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  Timestamp,
  DocumentReference,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCtONTFIjayMl8luNRN6xvWN-96DqW0Lbc",
  authDomain: "optimal-card-399123.firebaseapp.com",
  projectId: "optimal-card-399123",
  storageBucket: "optimal-card-399123.appspot.com",
  messagingSenderId: "670321990401",
  appId: "1:670321990401:web:663e329877286332131900",
  measurementId: "G-GEYFB0EZCH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export enum ECollections {
  USER = "users",
  FIDELITY_PLANS = "FidelityPlans",
  PURCHASES = "Purchases",
  SUBSCRIBERS = "Subscribers",
  EVENTS = "events",
  ALLOWED_PLANS = "allowed_plans",
  CHECKINS = "checkins"
}

export class firebaseServices {
  public async findDocumentByChunks(
    collectionName: string,
    key: string,
    operator: WhereFilterOp,
    values: any[]
  ): Promise<any[]> {
    try {
      const collectionRef = collection(db, collectionName);
      const dataResult: any[] = [];
      const chunks: any[] = [];
      for (let i = 0; i < values.length, (i += 30); i += 30) {
        chunks.push(values.slice(i, i + 30));
      }
      const q = query(collectionRef, where(key, operator, values));
      const docSnap = (await getDocs(q)).forEach((doc) => {
        dataResult.push({ id: doc.id, data: doc.data() });
      });
      return dataResult;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async findDocumentByQuery(
    collectionName: string,
    key: string,
    operator: WhereFilterOp,
    value: string,
    startDate?: Date,
    endDate?: Date
  ): Promise<any[]> {
    try {
      const collectionRef = collection(db, collectionName);
      let q = query(collectionRef, where(key, operator, value));

      if (startDate) {
        const startTimestamp = Timestamp.fromDate(startDate);

        q = query(q, where("month_reference", ">=", startTimestamp));
      }

      if (endDate) {
        const endTimestamp = Timestamp.fromDate(endDate);

        q = query(q, where("month_reference", "<=", endTimestamp));
      }

      const dataResult: any[] = [];
      const docSnap = await getDocs(q);
      docSnap.forEach((doc) => {
        dataResult.push({ id: doc.id, data: doc.data() });
      });
      return dataResult;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async getDocumentByReference(
    document_reference: DocumentReference
  ){
    try {
      const docRef = doc(db, document_reference.path);

      const docSnap = await getDoc(docRef);
      return docSnap.data();
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async findSubcollection(collectionPath: string) {
    const tasksRef = collection(db, collectionPath);
    const subCollections: any[] = [];
    try {
      const snapshot = await getDocs(tasksRef);

      snapshot.forEach((doc) => {
        subCollections.push({ id: doc.id, ...doc.data() });
      });

      return subCollections;
    } catch (error) {
      throw error;
    }
  }

  public async findDocumentById(collectionName: string, docId: string) {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);
      return docSnap?.data();
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async setDocument(
    collectionPath: string,
    data: any,
    documentId?: string
  ): Promise<any> {
    try {
      const result =
        documentId !== undefined
          ? await setDoc(doc(db, collectionPath, documentId), data)
          : await addDoc(collection(db, collectionPath), data);
      return result;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async updateDocument(
    collectionPath: string,
    data: any,
    documentId: string
  ): Promise<any> {
    try {
      const result = updateDoc(doc(db, collectionPath, documentId), data, {
        mergeFields: true,
      });
      return result;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }
}
