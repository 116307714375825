import { createStore } from 'effector'

import * as actions from './actions'

interface IUseAuth {
  data?: any
  isLoading?: boolean
  hasError?: boolean
}

export const store = createStore<IUseAuth>(
  {
    isLoading: false,
    hasError: false,
    data: {
      id: localStorage.getItem('id'),
      name: localStorage.getItem('name') || '',
      last_name: localStorage.getItem('last_name') || '',
      email: localStorage.getItem('email') || '',
      accessToken: localStorage.getItem('accessToken') || '',
      profilePic: localStorage.getItem('profilePic') || '',
      admission_date: localStorage.getItem('admission_date') || '',
      user_type: localStorage.getItem('user_type') || '',
      is_active: localStorage.getItem('is_active') || '',
      manager: localStorage.getItem('manager') || '',
      subscriber: localStorage.getItem('subscriber') || '',
      usertype: localStorage.getItem('usertype') || '',
      isLogged: localStorage.getItem('isLogged') || ''
    }
  },
  {
    name: 'auth'
  }
)

store.on(actions.handleSignIn, (state) => ({
  ...state,
  isLoading: true,
  hasError: false
}))

store.on(actions.handleSignIn.doneData, (state, data) => ({
  ...state,
  data,
  isLoading: false,
  hasError: false
}))

store.on(actions.handleSignIn.fail, (state) => ({
  ...state,
  isLoading: false,
  hasError: true
}))

store.on(actions.handleLogout, (state) => {
  localStorage.removeItem('id')
  localStorage.removeItem('name')
  localStorage.removeItem('email')
  localStorage.removeItem('last_name')
  localStorage.removeItem('admission_date')
  localStorage.removeItem('user_type')
  localStorage.removeItem('creation_date')
  localStorage.removeItem('is_active')
  localStorage.removeItem('manager')
  localStorage.removeItem('subscriber')
  localStorage.removeItem('usertype')
  localStorage.removeItem('id')
  localStorage.removeItem('profilePic')
  localStorage.removeItem('accessToken')
  window.location.reload()

  return {
    ...state,
    data: {
      isLogged: false
    }
  }
})
